import { useEffect,useRef } from 'react';
import './App.css';
import { useState } from 'react';
import logo from './res/logo.png';
import desale from './res/desale.png'
import service from './res/service.png';
import send_data from './lib';
// import QRCode from "react-qr-code";
import adws_img from './res/index_ptrz.png';

function App() {

  const [video_src,set_video_src]=useState('');
  const [video_status,set_video_status]=useState(false);
  const video_ref=useRef();
  const [login_mobile,set_login_mobile]=useState(null);

  const [login_status,set_login_status]=useState(false);
  const [cz_status,set_cz_status]=useState(false);
  const mobile_ref=useRef(null);
  const rand_ref=useRef(null); 


  useEffect(()=>{
      const cookie=localStorage.getItem("cookie");
      const mobile=localStorage.getItem("mobile");
      if(cookie!=null && cookie!='' && mobile!=null && mobile!='')
      {        
        set_login_mobile(mobile);
        return;
      }    
      send_data({"api":"get_cookie"},(e)=>{
                if(e.status=='success')
                localStorage.setItem("cookie",e.cookie);
            })  
  },[]);


  function to_center(e)
  {
    e.preventDefault();
    send_data({"api":"get_vip_status","mobile":login_mobile},(e)=>{
      if(e.status=='success') 
      {
         window.open("https://ai.anyimeta.com/aigc/login");
      }    
      else
      {
        alert('请先开通会员');
      } 
    })
    
  }

  function open_vip(e)
  {
    e.preventDefault();
    if(login_mobile==null)
    {
      set_login_status(true);
      return;
    }
    set_cz_status(true);

  }

  function My_money()
    {
        const [cz_money,set_cz_money]=useState();
        const  [wxpay_status,set_wxpay_status]=useState(false);
        const [qrcode_value,set_qrcode_value]=useState('');
        const [qrcode_serial,set_qrcode_serial]=useState('');
       
        
        function Pay_ok()
        {
            return(
                <div className="pay_ok">
                    <div className="ok_img" />
                        支付成功
                </div>
            )
        }
        function Wx_pay()
        {
            function get_serial_status()
            {
                send_data({"api":"get_wxpay_status","serial":qrcode_serial},(e)=>{
                    set_wxpay_status(e.status==1?true:false);
                 })
            }          
            useEffect(()=>{
                let timerId=setInterval(get_serial_status,1000);
                return()=>{
                    clearInterval(timerId);                   
                }
            },[])
            return(
              <div className='wxpay_panel'>
                <div className="wxpay_box">
                    <div className="wx_logo">
                    <div onClick={()=>set_cz_status(false)} className="close_btn fl_right"></div>
                    </div> 
                    <div className="wx_qr_box">
                        {/* <div className="qr_left">
                            <span className="norm_str">订单类型：购买数字人</span> 
                            <span className="norm_str">订单号：{qrcode_serial}</span> 
                            <span className="norm_str">充值账号：{login_mobile}</span> 
                            <span className="norm_str">支付金额：{cz_money}元</span> 
                        </div> */}
                        <div className="qr_right">
                            <img src={"https://api.pwmqr.com/qrcode/create/?url="+qrcode_value} />
                            {/* <QRCode
                            className="qrcode"
                            size={256}
                            value={qrcode_value}
                            /> */}
                            <div className="pay_money_title">打开微信扫一扫</div>
                            <div className="pay_money">￥{cz_money}</div>
                        </div>
                        {wxpay_status==true?<Pay_ok />:<></>}
                    </div>
                </div>
                </div>
            )
        }
  
        function get_wxpay_serial()
        {  
            let cz_money=1980;
            set_cz_money(cz_money);   
            send_data({"api":"get_wx_pay","mobile":login_mobile,"cookie":localStorage.getItem("cookie"),"money":cz_money},(e)=>{
                set_qrcode_value(e.code_url);
                set_qrcode_serial(e.serial);
                set_cz_status(true);
            })
            
        }

        useEffect(()=>{
          get_wxpay_serial();
        },[])

        return(
            <div>
                <Wx_pay />            
            </div>
        )
    }

  function show_modal(src){
    set_video_status(true);
    set_video_src(src);     
  }

  function Login_btn(props){
    return(
        <>
            <div className="login_btn" onClick={()=>handle_login(props.check_status)}>
                立即登录
            </div>
        </>
        
    )
} 
//发送验证码
function exe_ver_btn(ver_btn_str,set_ver_btn_str,mobile)
{

  if(mobile=='' || mobile==null || mobile.length!=11 || /^\d+$/.test(mobile)==false)
  {
      alert('请输入正确的手机号');
      return false;
  }
  if(ver_btn_str!='获取')return;
  set_ver_btn_str(60);
  ver_btn_str=60;
  let timerID=setInterval(()=>{
      if(ver_btn_str>1)
      ver_btn_str--;
      else
      {
      ver_btn_str='获取';
      clearInterval(timerID);
      }
      set_ver_btn_str(ver_btn_str);
  },1000)

  //发送验证码
      let send_par={
          "api":"send_message",
          "mobile":mobile,
          "cookie":localStorage.getItem("cookie")
      }
      send_data(send_par,(e)=>{                         
          if(e.status=='success')
          {
            
          }
          else{
              alert(e.status);
          }

      });

}

function handle_login(check_status){
    const mobile=mobile_ref.current.value;
    const rand=rand_ref.current.value;

    if(!check_status)
    {
      alert('请阅读并同意《网站条款》');
      return false
    }

    if(mobile.length!=11 || /^\d+$/.test(mobile)==false)
    {
        alert('请输入正确的手机号');
        return false;
    }
    if(rand.length!=4 || /^\d+$/.test(rand)==false)
    {
        alert('请输入正确的短信验证码');
        return false;
    }

    //登录
    let send_par={
        "api":"login",
        "mobile":mobile,
        "rand":rand,
        "cookie":localStorage.getItem("cookie")
    }
    send_data(send_par,(e)=>{         
      if(e.status=='success')
      {
        set_login_status(false);
        set_login_mobile(mobile);
        localStorage.setItem("mobile",mobile);
      }
      else
      {
        alert('登录失败，请重试');
      }                
        
    });
}

  function Log_layer(){
    const [mobile,set_mobile]=useState("");
    const [rand,set_rand]=useState("");
    const [ver_btn_str,set_ver_btn_str]=useState('获取');
    const [check_status,set_check_status]=useState(false);
    return(
        <div className="log_layer">
            <div className="log_box">
                <div className="log_close">
                    <div className="close_btn" onClick={()=>set_login_status(false)}></div>
                </div>
                <div className="log_title">
                    手机号登录<span>(首次登录自动注册)</span>
                </div>
                <div className="input_box">
                      
                     <div className="input_box_inputdiv">
                        <div className="mobile_svg"></div>
                        <input ref={mobile_ref} className="mobile_input" value={mobile} type='tel' onChange={(e)=>set_mobile(e.target.value)} maxLength="11" placeholder="手机号码" />
                     </div>
                     <div className="input_box_inputdiv">
                        <div className="ran_svg"></div>
                        <input ref={rand_ref} className="mobile_input" value={rand} maxLength="6" type='tel' onChange={(e)=>set_rand(e.target.value)} placeholder="验证码" />
                        <div className="get_rand" onClick={()=>exe_ver_btn(ver_btn_str,set_ver_btn_str,mobile)}>{ver_btn_str}</div>                            
                    </div> 
                    <div onClick={()=>set_check_status(!check_status)} className='ckbox'>
                      <input checked={check_status} type='checkbox' />
                      已阅读并同意
                      <a target='_blank' href='service.html'>《用户注册协议》</a>
                    </div>
                    <Login_btn check_status={check_status} />
                </div>
            </div>
        </div>
    )
}


  return (
    <div  id="kt_body"    data-bs-spy="scroll"    data-bs-target="#kt_landing_menu"    data-bs-offset={200}    className="bg-white position-relative" >
            <div className="d-flex flex-column flex-root">
            {/*begin::Header Section*/}
            <div className="mb-0" id="home">
              {/*begin::Wrapper*/}
              <div
                className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                style={{
                  backgroundImage: "url(https://ai.anyimeta.com/assets/saas/img/bg.png)"
                }}
              >
                {/*begin::Header*/}
                <div
                  className="landing-header"
                  data-kt-sticky="true"
                  data-kt-sticky-name="landing-header"
                  data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                >
                  {/*begin::Container*/}
                  <div className="container">
                    {/*begin::Wrapper*/}
                    <div className="d-flex align-items-center justify-content-between">
                      {/*begin::Logo*/}
                      <div className="d-flex align-items-center flex-equal">
                        {/*begin::Mobile menu toggle*/}
                        <button
                          className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                          id="kt_landing_menu_toggle"
                        >
                          {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                          <span className="svg-icon svg-icon-2hx">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                        {/*end::Mobile menu toggle*/}
                        {/*begin::Logo image*/}
                          <img
                            alt="Logo"
                            src={logo}
                            className="logo-default h-40px h-lg-60px"
                          />                     
                        {/*end::Logo image*/}
                      </div>
                      {/*end::Logo*/}
                      {/*begin::Menu wrapper*/}
                      <div className="d-lg-block" id="kt_header_nav_wrapper">
                        <div
                          className="d-lg-block p-5 p-lg-0"
                          data-kt-drawer="true"
                          data-kt-drawer-name="landing-menu"
                          data-kt-drawer-activate="{default: true, lg: false}"
                          data-kt-drawer-overlay="true"
                          data-kt-drawer-width="200px"
                          data-kt-drawer-direction="start"
                          data-kt-drawer-toggle="#kt_landing_menu_toggle"
                          data-kt-swapper="true"
                          data-kt-swapper-mode="prepend"
                          data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                        >
                          {/*begin::Menu*/}
                          <div
                            className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                            id="#kt_header_menu"
                          >
                          
                          </div>
                          {/*end::Menu*/}
                        </div>
                      </div>
                      {/*end::Menu wrapper*/}
                      {/*begin::Toolbar*/}
                      {login_mobile==null?
                      <div className="flex-equal text-end ms-1" onClick={()=>set_login_status(true)}>                      
                        <a
                          href="#"
                          className="btn btn-primary"
                        >
                          登录/注册
                        </a>
                      </div>:
                      <div className="flex-equal text-end ms-1">
                      <span className="text-white fw-bold hidden-xs">
                        欢迎：{login_mobile}
                      </span>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <a onClick={(e)=>to_center(e)}
                        href="#"
                        className="btn btn-primary"
                      >
                        个人中心
                      </a>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <a
                        onClick={()=>{set_login_mobile(null);localStorage.removeItem("mobile")}}
                        href="#"
                        className="btn btn-primary"
                      >
                        退出登录
                      </a>

                      </div>
                      }

                      {/*end::Toolbar*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Header*/}
                {/*begin::Landing hero*/}
                <div className="  d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                  <div className="container  gy-10">
                    <div className="row w-100 ">
                      {/*begin::Heading*/}
                      <div className="col-xl-6 text-start mt-15 mb-15">
                        {/*begin::Title*/}
                        <h2 className="text-white fw-bolder fs-2x fs-lg-3x">
                          行业领先的
                        </h2>
                        <h2 className="text-white fw-bolder fs-2x fs-lg-3x mb-10">
                          AIGC数字人孵化平台
                        </h2>
                        {/*end::Title*/}
                        <p className="fs-5 text-muted fw-bold mt-10 mb-10">
                          让全球3亿人拥有自己的数字分身，实现百倍效率提升！
                        </p>
                        {/*begin::Action*/}
                        <a
                          href="#pricing"
                          className="btn btn-primary btn-lg mt-10 me-2 "
                          data-kt-scroll-toggle="true"
                          data-kt-drawer-dismiss="true"
                        >
                          查看产品价格
                        </a>
                        
                        {/*end::Action*/}
                      </div>
                      <div
                        className="col-xl-6 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom "
                        style={{
                          backgroundImage:
                            "url(https://ai.anyimeta.com/assets/saas/img/index_left_1.png?20230617)",
                          backgroundPositionX: "right"
                        }}
                      ></div>
                      {/*end::Heading*/}
                    </div>
                  </div>
                </div>
                {/*end::Wrapper*/}
                {/*begin::Curve bottom*/}
                <div className="landing-curve landing-dark-color mb-10 mb-lg-20"></div>
                {/*end::Curve bottom*/}
              </div>
              {/*end::Header Section*/}
              {/*begin::How It Works Section*/}
              <div className="">
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Heading*/}
                  <div className="text-center mb-17">
                    {/*begin::Title*/}
                    <h3
                      className="fs-2hx text-dark mb-5"
                      id="how-it-works"
                      data-kt-scroll-offset="{default: 100, lg: 150}"
                    >
                      基于AIGC+数字人技术，联合9000多创作者，孵化百万数字达人
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Text*/}
                    <div className="fs-5 text-muted fw-bold">
                      集数字分身技术、AIGC内容生成技术及AI-SaaS营销系统于一体的一站式孵化平台
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Row*/}
                  <div className="row w-100 gy-10 mb-md-9">
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className="text-center mb-10 mb-md-0">
                        {/*begin::Illustration*/}
                        <img
                          src="https://ai.anyimeta.com/assets/saas/img/video.svg"
                          className="mh-100px mb-10"
                          alt=""
                        />
                        {/*end::Illustration*/}
                        {/*begin::Heading*/}
                        <div className="d-flex flex-center mb-5">
                          {/*begin::Badge*/}
                          {/*end::Badge*/}
                          {/*begin::Title*/}
                          <div className="fs-5 fs-lg-3 fw-bolder text-dark">
                            AIGC及30万脚本库
                          </div>
                          {/*end::Title*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Description*/}
                        <div className="fw-bold fs-5 fs-lg-5 text-muted ">
                          AIGC大模型及9000多专业创作者
                          <br />
                          帮助数字达人高效解决内容问题
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className="text-center mb-10 mb-md-0">
                        {/*begin::Illustration*/}
                        <img
                          src="https://ai.anyimeta.com/assets/saas/img/flow.svg"
                          className="mh-100px mb-10"
                          alt=""
                        />
                        {/*end::Illustration*/}
                        {/*begin::Heading*/}
                        <div className="d-flex flex-center mb-5">
                          {/*begin::Badge*/}
                          {/*end::Badge*/}
                          {/*begin::Title*/}
                          <div className="fs-5 fs-lg-3 fw-bolder text-dark">
                            1/10的市场成本
                          </div>
                          {/*end::Title*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Description*/}
                        <div className="fw-bold fs-5 fs-lg-5 text-muted">
                          赤生数字人以领先的矩阵训练算法技术
                          <br />
                          让数字人技术全面化普及并落地应用
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className="text-center mb-10 mb-md-0">
                        {/*begin::Illustration*/}
                        <img
                          src="https://ai.anyimeta.com/assets/saas/img/marketing.svg"
                          className="mh-100px mb-10"
                          alt=""
                        />
                        {/*end::Illustration*/}
                        {/*begin::Heading*/}
                        <div className="d-flex flex-center mb-5">
                          {/*begin::Badge*/}
                          {/*end::Badge*/}
                          {/*begin::Title*/}
                          <div className="fs-5 fs-lg-3 fw-bolder text-dark">
                            完整孵化变现场景
                          </div>
                          {/*end::Title*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Description*/}
                        <div className="fw-bold fs-5 fs-lg-5 text-muted">
                          通过平台进行商业派单及IP授权交易
                          <br />
                          变现，更有前置费及奖励补贴
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Row*/}
                  {/*begin::Product slider*/}
                  <div className="tns tns-default">
                    {/*begin::Slider*/}
                    <div
                      data-tns="true"
                      data-tns-loop="true"
                      data-tns-swipe-angle="false"
                      data-tns-speed={2000}
                      data-tns-autoplay="true"
                      data-tns-autoplay-timeout={18000}
                      data-tns-controls="true"
                      data-tns-nav="false"
                      data-tns-items={1}
                      data-tns-center="false"
                      data-tns-dots="false"
                      data-tns-prev-button="#kt_team_slider_prev1"
                      data-tns-next-button="#kt_team_slider_next1"
                    >
                      {/*begin::Item*/}
                      <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                        <img
                          src="https://ai.anyimeta.com/assets/saas/img/demo1_1.png?20230617"
                          className=" mw-100"
                          alt=""
                        />
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Slider*/}
                    {/*begin::Slider button*/}
                    <button
                      className="btn btn-icon btn-active-color-primary d-none"
                      id="kt_team_slider_prev1"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr074.svg*/}
                      <span className="svg-icon svg-icon-3x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </button>
                    {/*end::Slider button*/}
                    {/*begin::Slider button*/}
                    <button
                      className="btn btn-icon btn-active-color-primary d-none"
                      id="kt_team_slider_next1"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
                      <span className="svg-icon svg-icon-3x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </button>
                    {/*end::Slider button*/}
                  </div>
                  {/*end::Product slider*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::How It Works Section*/}
              {/*begin::Statistics Section*/}
              <div className="mt-sm-n10 d-none">
                {/*begin::Curve top*/}
                <div className="landing-curve landing-dark-color">
                  <svg
                    viewBox="15 -1 1470 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                {/*end::Curve top*/}
                {/*begin::Wrapper*/}
                <div className="pb-15 pt-18 landing-dark-bg">
                  {/*begin::Container*/}
                  <div className="container">
                    {/*begin::Heading*/}
                    <div
                      className="text-center mt-15 mb-18"
                      data-kt-scroll-offset="{default: 100, lg: 150}"
                    >
                      {/*begin::Title*/}
                      <h3 className="fs-2hx text-white fw-bolder mb-5">
                        平台创意方认证
                      </h3>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="fs-5 text-gray-700 fw-bold">
                        平台入驻内容创作者7000+，拍摄剪辑团队5000+，10万+短视频内容脚本库，领先的一站式SaaS短视频创作平台
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Statistics*/}
                    <div className="d-flex flex-center text-center">
                      {/*begin::Items*/}
                      <div className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                        {/*begin::Item*/}
                        <div
                          className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                          style={{
                            backgroundImage:
                              "url(https://ai.anyimeta.com/assets/saas/img/octagon.svg"
                          }}
                        >
                          {/*begin::Symbol*/}
                          {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                          <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                x={2}
                                y={2}
                                width={9}
                                height={9}
                                rx={2}
                                fill="black"
                              />
                              <rect
                                opacity="0.3"
                                x={13}
                                y={2}
                                width={9}
                                height={9}
                                rx={2}
                                fill="black"
                              />
                              <rect
                                opacity="0.3"
                                x={13}
                                y={13}
                                width={9}
                                height={9}
                                rx={2}
                                fill="black"
                              />
                              <rect
                                opacity="0.3"
                                x={2}
                                y={13}
                                width={9}
                                height={9}
                                rx={2}
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Symbol*/}
                          {/*begin::Info*/}
                          <div className="mb-0">
                            {/*begin::Value*/}
                            <div className="fs-lg-2hx fs-2x fw-bolder text-white d-flex flex-center">
                              <div
                                className="min-w-70px"
                                data-kt-countup="true"
                                data-kt-countup-value={7395}
                                data-kt-countup-suffix="+"
                              >
                                0
                              </div>
                            </div>
                            {/*end::Value*/}
                            {/*begin::Label*/}
                            <span className="text-gray-600 fw-bold fs-5 lh-0">
                              认证编剧
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div
                          className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                          style={{
                            backgroundImage:
                              "url(https://ai.anyimeta.com/assets/saas/img/octagon.svg"
                          }}
                        >
                          {/*begin::Symbol*/}
                          {/*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/}
                          <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Symbol*/}
                          {/*begin::Info*/}
                          <div className="mb-0">
                            {/*begin::Value*/}
                            <div className="fs-lg-2hx fs-2x fw-bolder text-white d-flex flex-center">
                              <div
                                className="min-w-70px"
                                data-kt-countup="true"
                                data-kt-countup-value={3000}
                                data-kt-countup-suffix="+"
                              >
                                0
                              </div>
                            </div>
                            {/*end::Value*/}
                            {/*begin::Label*/}
                            <span className="text-gray-600 fw-bold fs-5 lh-0">
                              认证摄影
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div
                          className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                          style={{
                            backgroundImage:
                              "url(https://ai.anyimeta.com/assets/saas/img/octagon.svg"
                          }}
                        >
                          {/*begin::Symbol*/}
                          {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg*/}
                          <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Symbol*/}
                          {/*begin::Info*/}
                          <div className="mb-0">
                            {/*begin::Value*/}
                            <div className="fs-lg-2hx fs-2x fw-bolder text-white d-flex flex-center">
                              <div
                                className="min-w-70px"
                                data-kt-countup="true"
                                data-kt-countup-value={2000}
                                data-kt-countup-suffix="+"
                              >
                                0
                              </div>
                            </div>
                            {/*end::Value*/}
                            {/*begin::Label*/}
                            <span className="text-gray-600 fw-bold fs-5 lh-0">
                              认证剪辑
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                      </div>
                      {/*end::Items*/}
                    </div>
                    {/*end::Statistics*/}
                    {/*begin::Testimonial*/}
                    <div className="fs-2 fw-bold text-muted text-center mb-3">
                      <span className="fs-1 lh-1 text-gray-700">“</span>
                      客户出需求，平台从海量作品中优选适合剧本完成摄制，
                      <br />
                      <span className="text-gray-700 me-1">精准定制化</span>
                      用户需求与产品做结合
                      <span className="fs-1 lh-1 text-gray-700">“</span>
                    </div>
                    {/*end::Testimonial*/}
                    {/*begin::Author*/}
                    <div className="fs-2 fw-bold text-muted text-center">
                      <span className="fs-4 fw-bolder text-gray-600">
                        成本只有原来的1/5不到
                      </span>
                    </div>
                    {/*end::Author*/}
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Curve bottom*/}
                <div className="landing-curve landing-dark-color">
                  <svg
                    viewBox="15 12 1470 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                {/*end::Curve bottom*/}
              </div>
              {/*end::Statistics Section*/}
              {/*begin::How It Works Section*/}
              <div className="mt-15 d-none" style={{ backgroundColor: "#767e82" }}>
                {/*begin::Container*/}
                <div className="container mt-15">
                  {/*begin::Row*/}
                  <div
                    className="row w-100 gy-5 mb-md-9"
                    style={{ cursor: "pointer" }}
                    onClick={()=>show_modal('https://smsaas.oss-accelerate.aliyuncs.com/shenma_saas_info.mp4')}
                  >
                    {/*begin::Col*/}
                    <div className="col-md-12">
                      <img
                        src="https://ai.anyimeta.com/assets/saas/img/index/video_bg.png"
                        className="w-100 float-end"
                        alt=""
                      />
                      {/*  <div  style="display: flex;justify-content: center;align-items: center;background: #000;" class="align-items-center mb-1">
                              <video controls class="mb-5 w-100">
                                  <source src="https://smsaas.oss-accelerate.aliyuncs.com/shenma_saas_info.mp4" type="video/mp4">
                                </video>
                          </div> */}
                    </div>
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::How It Works Section*/}
              {/*begin::How It Works Section*/}
              <div className="mt-15" style={{ backgroundColor: "#1e161e" }}>
                {/*begin::Container*/}
                <div className="container mt-15">
                  {/*begin::Row*/}
                  <div className="row w-100 gy-5 mb-md-9">
                    {/*begin::Col*/}
                    <div className="col-md-12">
                      {/*begin::Illustration*/}
                      <img
                        src="https://ai.anyimeta.com/assets/saas/img/img1_1.png?3"
                        className="w-100"
                        alt=""
                      />
                      {/*end::Illustration*/}
                    </div>
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::How It Works Section*/}
              {/*begin::How It Works Section*/}
              <div className="mb-n10  ">
                {/*begin::Container*/}
                <div className="container" id="achievements">
                  {/*begin::Row*/}
                  <div className="row ">
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5">
                      {/*begin::Illustration*/}
                      <img
                        src={adws_img}
                        className="w-100"
                        alt=""
                      />
                      {/*end::Illustration*/}
                    </div>
                    {/*end::Col*/}
                    <div className="col-md-8 px-15">
                      <div className="py-10">
                        {/*begin::Title*/}
                        <h3 className="fs-2hx text-dark mb-5">关于我们</h3>
                        {/*end::Title*/}                      
                        <p className="text-muted pb-10 fs-5">
                          赤生数字人聚焦短视频行业，深耕全球前沿科技领域。基于自研的AIGC视频生成技术、数字人IP技术、区块链版权存证技术以及短视频AI营销系统，为用户提供：品牌营销、内容创作及达人孵化等服务，让用户一站式完成短视频营销及数字化达人孵化变现服务。
                        </p>
                        {/* <div class="row ">
                          <div class="col-xxl-2 text-center">
                            <img src="https://ai.anyimeta.com/assets/saas/img/miniprogram.jpg" class="mh-200px " alt="">
                          </div>
                          <div class="col-xxl-10">
                          </div>
                        </div> */}
                        <div className="row d-none">
                          <div className="col-xxl-4 py-2">
                            <div className="col-1">
                              {/*begin::Illustration*/}
                              <img
                                src="https://ai.anyimeta.com/assets/saas/img/index/bj.png"
                                className="mh-30px float-start"
                                alt=""
                              />
                              {/*end::Illustration*/}
                            </div>
                            <div className="col-11">
                              <div
                                style={{
                                  paddingLeft: 10,
                                  lineHeight: 30,
                                  fontWeight: "600；"
                                }}
                                className="fs-3 float-start"
                              >
                                编剧
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4 py-2">
                            <div className="col-1">
                              {/*begin::Illustration*/}
                              <img
                                src="https://ai.anyimeta.com/assets/saas/img/index/ps.png"
                                className="mh-30px float-start"
                                alt=""
                              />
                              {/*end::Illustration*/}
                            </div>
                            <div className="col-11">
                              <div
                                style={{
                                  paddingLeft: 10,
                                  lineHeight: 30,
                                  fontWeight: "600；"
                                }}
                                className="fs-3 float-start"
                              >
                                拍摄
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4 py-2">
                            <div className="col-1">
                              {/*begin::Illustration*/}
                              <img
                                src="https://ai.anyimeta.com/assets/saas/img/index/hq.png"
                                className="mh-30px float-start"
                                alt=""
                              />
                              {/*end::Illustration*/}
                            </div>
                            <div className="col-11">
                              <div
                                style={{
                                  paddingLeft: 10,
                                  lineHeight: 30,
                                  fontWeight: "600；"
                                }}
                                className="fs-3 float-start"
                              >
                                后期
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4 py-10">
                            <div className="col-6">
                              <a
                                href="#"
                                className="w-100 btn btn-primary"
                              >
                                进入系统
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::How It Works Section*/}
              {/*begin::Team Section*/}
            
              {/*end::Team Section*/}
              {/*begin::How It Works Section*/}
              <div className="mt-15 z-index-2">
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Heading*/}
                  <div className="text-center mb-17">
                    {/*begin::Title*/}
                    <h3
                      className="fs-2hx text-dark mb-5"
                      id="portfolio"
                      data-kt-scroll-offset="{default: 100, lg: 150}"
                    >
                      AIGC数字人应用场景
                    </h3>
                    {/*end::Title*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Row*/}
                  <div className="row w-100 gy-5 mb-md-9">
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>{show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E9%A4%90%E9%A5%AE%E7%BE%8E%E9%A3%9F.mp4')}}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/mscy.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{ fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              餐饮美食
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E7%BE%8E%E5%8F%91%E7%BE%8E%E7%94%B2.mp4')}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/mfmj.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              美发美甲
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E9%85%92%E5%BA%97%E6%97%85%E6%B8%B8.mp4')}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/jdly.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              酒店旅游
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E7%BE%8E%E5%A6%86%E8%A1%8C%E4%B8%9A.mp4')}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/mzhy.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              美妆行业
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>{show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E6%AF%8D%E5%A9%B4%E5%A5%B6%E7%B2%89.mp4')}}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/mynf.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              母婴奶粉
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>{show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E7%94%9F%E9%B2%9C%E9%A3%9F%E5%93%81.mp4')}}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/sxsp.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              生鲜食品
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>{show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E9%85%92%E6%B0%B4%E9%A5%AE%E6%96%99.mp4')}}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/jsyl.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              {" "}
                              酒水饮料
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>show_modal('https://smsaas.oss-accelerate.aliyuncs.com/Industry_demo/%E5%AE%B6%E5%B1%85%E5%8E%A8%E5%8D%AB.mp4')}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/jjcw.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              家居厨卫
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="video_list col-md-4 px-5">
                      {/*begin::Story*/}
                      <div className=" mb-10 mb-md-0">
                        <div
                          className="row border  py-5"
                          style={{
                            boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                          }}
                          onClick={()=>show_modal('https://smsaas.oss-cn-hangzhou.aliyuncs.com/Industry_demo/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF.mp4')}
                        >
                          <div className="col-4">
                            {/*begin::Illustration*/}
                            <img
                              src="https://ai.anyimeta.com/assets/saas/img/index/dzcp.png"
                              className="mh-50px float-end"
                              alt=""
                            />
                            {/*end::Illustration*/}
                          </div>
                          <div className="col-4">
                            <div
                              style={{  fontSize: 20, fontWeight: 600 }}
                              className="float-start"
                            >
                              热点资讯
                            </div>
                          </div>
                          <div className="col-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                            <div className="h-50px d-flex align-items-center">
                              <div
                                style={{ backgroundColor: "#C8CFD7", borderRadius: 18 }}
                              >
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr027.svg*/}
                                <span className="svg-icon svg-icon-light svg-icon-2hx">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                            </div>
                            {/*end::Svg Icon*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::How It Works Section*/}
              {/*begin::How It Works Section*/}
              <div className="mt-15 d-none" style={{ backgroundColor: "#241d1d" }}>
                {/*begin::Container*/}
                <div className="container mt-15">
                  {/*begin::Row*/}
                  <div className="row w-100 gy-5 mb-md-9">
                    {/*begin::Col*/}
                    <div className="col-md-12">
                      {/*begin::Illustration*/}
                      <img
                        src="https://ai.anyimeta.com/assets/saas/img/index/img2.png"
                        className="w-100"
                        alt=""
                      />
                      {/*end::Illustration*/}
                    </div>
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::How It Works Section*/}
              {/*begin::Projects Section*/}
              <div className="mt-20 position-relative z-index-2">
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Card*/}
                  <div
                    className=" "
                    style={{
                      filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))"
                    }}
                  >
                    {/*begin::Card body*/}
                    <div className="mt-15 mb-15">
                      {/*begin::Heading*/}
                      <div className="text-center mb-5 mb-lg-10">
                        {/*begin::Title*/}
                        <h3
                          className="fs-2hx text-dark mb-5"
                          data-kt-scroll-offset="{default: 100, lg: 150}"
                        >
                          赤生数字人生态产品
                        </h3>
                        {/*end::Title*/}
                        <p className="text-muted fs-5 mb-15">
                          全场景全链路的赤生数字人产品。助力企业/个人完成数字化升级
                        </p>
                      </div>
                      {/*end::Heading*/}
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 box">
                          <a
                            href="#"
                            className="text-primary "
                          >
                            <div className="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                              <div className="icons text-primary text-center mx-auto">
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/communication/com006.svg*/}
                                <span className="svg-icon svg-icon-primary svg-icon-5x">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                              <div className="mt-2 mb-5  card-body p-0 content">
                                <h5 className="mt-4 mb-5 fs-5 fs-lg-3 fw-bolder text-dark">
                                  数字达人入驻
                                </h5>
                                <p className="fw-bold fs-5 fs-lg-5 text-muted ">
                                  赤生数字人基于AIGC+数字人技术，联合9000多创作者，孵化百万数字达人。
                                </p>
                                <i className="uil uil-angle-right-b align-middle" />
                              </div>
                            </div>
                          </a>
                        </div>
                        {/*end col*/}
                        <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-md-0 box">
                          <a
                            href="#"
                            className="text-primary "
                          >
                            <div className="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                              <div className="icons text-primary text-center mx-auto">
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/technology/teh008.svg*/}
                                <span className="svg-icon  svg-icon-primary svg-icon-5x">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M11 6.5C11 9 9 11 6.5 11C4 11 2 9 2 6.5C2 4 4 2 6.5 2C9 2 11 4 11 6.5ZM17.5 2C15 2 13 4 13 6.5C13 9 15 11 17.5 11C20 11 22 9 22 6.5C22 4 20 2 17.5 2ZM6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13ZM17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M17.5 16C17.5 16 17.4 16 17.5 16L16.7 15.3C16.1 14.7 15.7 13.9 15.6 13.1C15.5 12.4 15.5 11.6 15.6 10.8C15.7 9.99999 16.1 9.19998 16.7 8.59998L17.4 7.90002H17.5C18.3 7.90002 19 7.20002 19 6.40002C19 5.60002 18.3 4.90002 17.5 4.90002C16.7 4.90002 16 5.60002 16 6.40002V6.5L15.3 7.20001C14.7 7.80001 13.9 8.19999 13.1 8.29999C12.4 8.39999 11.6 8.39999 10.8 8.29999C9.99999 8.19999 9.20001 7.80001 8.60001 7.20001L7.89999 6.5V6.40002C7.89999 5.60002 7.19999 4.90002 6.39999 4.90002C5.59999 4.90002 4.89999 5.60002 4.89999 6.40002C4.89999 7.20002 5.59999 7.90002 6.39999 7.90002H6.5L7.20001 8.59998C7.80001 9.19998 8.19999 9.99999 8.29999 10.8C8.39999 11.5 8.39999 12.3 8.29999 13.1C8.19999 13.9 7.80001 14.7 7.20001 15.3L6.5 16H6.39999C5.59999 16 4.89999 16.7 4.89999 17.5C4.89999 18.3 5.59999 19 6.39999 19C7.19999 19 7.89999 18.3 7.89999 17.5V17.4L8.60001 16.7C9.20001 16.1 9.99999 15.7 10.8 15.6C11.5 15.5 12.3 15.5 13.1 15.6C13.9 15.7 14.7 16.1 15.3 16.7L16 17.4V17.5C16 18.3 16.7 19 17.5 19C18.3 19 19 18.3 19 17.5C19 16.7 18.3 16 17.5 16Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                              <div className="mt-2  mb-5 card-body p-0 content">
                                <h5 className="mt-4 mb-5 fs-5 fs-lg-3 fw-bolder text-dark">
                                  矩阵团长合作
                                </h5>
                                <p className="fw-bold fs-5 fs-lg-5 text-muted ">
                                  赤生数字人完整的分销合作体系，从培训、产品到营销均提供全方面扶持!
                                </p>
                                <i className="uil uil-angle-right-b align-middle" />
                              </div>
                            </div>
                          </a>
                        </div>
                        {/*end col*/}
                        <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-md-0 box">
                          <a
                            href="#"
                            className="text-primary "
                          >
                            <div className="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                              <div className="icons text-primary text-center mx-auto">
                                {/*begin::Svg Icon | path: assets/media/icons/duotune/ecommerce/ecm004.svg*/}
                                <span className="svg-icon svg-icon-primary svg-icon-5x">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M14 4H10V10H14V4Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M17 4H20L22 10H18L17 4Z"
                                      fill="black"
                                    />
                                    <path
                                      opacity="0.3"
                                      d="M7 4H4L2 10H6L7 4Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </div>
                              <div className="mt-2 mb-5  card-body p-0 content">
                                <h5 className="mt-4 mb-5 fs-5 fs-lg-3 fw-bolder text-dark">
                                  品牌方/企业合作
                                </h5>
                                <p className="fw-bold fs-5 fs-lg-5 text-muted ">
                                  数字人营销支持企业所有类目品牌，虚拟商品、实物
                                  商品、本地生活商家均可。
                                </p>
                                <i className="uil uil-angle-right-b align-middle" />
                              </div>
                            </div>
                          </a>
                        </div>
                        {/*end col*/}
                      </div>
                    </div>
                    {/*end::Card body*/}
                  </div>
                  {/*end::Card*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Projects Section*/}
              {/*begin::Pricing Section*/}
              <div className=" mt-20" id="pricing">
                {/*begin::Wrapper*/}
                <div className="py-20 landing-dark-bg">
                  {/*begin::Container*/}
                  <div className="container">
                    {/*begin::Plans*/}
                    <div className="d-flex flex-column container pt-lg-20">
                      {/*begin::Heading*/}
                      <div className="mb-13 text-center">
                        {/* <h1 class="fs-2hx fw-bolder text-white mb-5" >开通赤生数字人数字人孵化会员尊享特权</h1> */}
                        <img
                          src={desale}
                          className="mb-5"
                          alt=""
                          data-kt-scroll-offset="{default: 100, lg: 150}"
                          style={{ width: "30%" }}
                        />
                      </div>
                      {/*end::Heading*/}
                      {/*begin::Pricing*/}
                      <div className="text-center" id="kt_pricing">
                        {/*begin::Row*/}
                        <div className="row g-10" style={{"align-items":"center","justifyContent":'center'}}>
                          {/*begin::Col*/}
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-center">
                              {/*begin::Option*/}
                              <div className="w-100 d-flex flex-column flex-center rounded-3  bg-body py-10 px-10">
                                {/*begin::Heading*/}
                                <div className="mb-7 text-center">
                                  {/*begin::Title*/}
                                  <h1 className="text-dark mb-5 fw-boldest">
                                    数字人孵化会员
                                  </h1>
                                  {/*end::Title*/}
                                  {/*begin::Description*/}
                                  <div className="text-gray-400 fw-bold mb-5">
                                    AIGC克隆数字人1个+30分钟视频合成时长
                                  </div>
                                  {/*end::Description*/}
                                  {/*begin::Price*/}
                                  <div className="text-center">
                                    <span className="badge badge-light-danger">
                                      限时特惠
                                    </span>
                                    <br />
                                    <span
                                      className="mb-2 "
                                      style={{ color: "#3B3F49" }}
                                    >
                                      ¥
                                    </span>
                                    <span
                                      className="fs-3x fw-bolder "
                                      data-kt-plan-price-month={980}
                                      data-kt-plan-price-annual={980}
                                      style={{ color: "#3B3F49" }}
                                    >
                                      1980
                                    </span>
                                    <span className="fs-7 fw-bold text-gray-400">
                                      /<span data-kt-element="period">年</span>
                                    </span>
                                    <br />
                                    <span className="fw-bold text-gray-400">
                                      {/* 原价：<s>1980</s>/ */}
                                      {/* <span data-kt-element="period">年</span> */}
                                    </span>
                                  </div>
                                  {/*end::Price*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Select*/}
                                <div className="w-100" onClick={(e)=>open_vip(e)}>
                                  <a
                                    href="java"
                                    className="btn  w-100 btn-primary"
                                    style={{
                                      backgroundColor: "#3B3F49",
                                      color: "#FFD676"
                                    }}
                                  >
                                    立即开通会员
                                  </a>
                                </div>
                                <div className="mt-10">
                                  <div className="row text-center ">
                                    <div className="col-4">
                                      {/*begin::Illustration*/}
                                      <img
                                        src="https://ai.anyimeta.com/assets/saas/img/index_icon/icon_1.svg"
                                        className=""
                                        alt=""
                                        style={{ width: 40 }}
                                      />
                                      {/*end::Illustration*/}
                                      <p className="mt-1">数字人克隆</p>
                                    </div>
                                    <div className="col-4">
                                      {/*begin::Illustration*/}
                                      <img
                                        src="https://ai.anyimeta.com/assets/saas/img/index_icon/icon_2.svg"
                                        className=""
                                        alt=""
                                        style={{ width: 40 }}
                                      />
                                      {/*end::Illustration*/}
                                      <p className="mt-1">合成视频时长</p>
                                    </div>
                                    <div className="col-4">
                                      {/*begin::Illustration*/}
                                      <img
                                        src="https://ai.anyimeta.com/assets/saas/img/index_icon/icon_3.svg"
                                        className=""
                                        alt=""
                                        style={{ width: 40 }}
                                      />
                                      {/*end::Illustration*/}
                                      <p className="mt-1">智能脚本生成</p>
                                    </div>
                                  </div>
                                </div>
                                {/*end::Select*/}
                            
                              </div>
                              {/*end::Option*/}
                            </div>
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                         
                          {/*end::Col*/}
                          {/*begin::Col*/}

                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                      </div>
                      {/*end::Pricing*/}
                    </div>
                    {/*end::Plans*/}
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Pricing Section*/}
              {/*begin::Footer Section*/}
              <div className="mb-0 ">
                {/*begin::Wrapper*/}
                <div className="landing-dark-bg pt-0">
                  {/*begin::Separator*/}
                  <div className="landing-dark-separator" />
                  {/*end::Separator*/}
                  {/*begin::Container*/}
                  <div className="container mt-15">
                    {/*begin::Title*/}
                    <div className="mb-13 mt-15 text-center">
                      <h1 className="fs-2hx fw-bolder text-white mb-5 d-none">
                        赤生数字人专业顾问为您介绍
                      </h1>
                  
                    </div>
                    {/*end::Title*/}
                    {/*begin::Wrapper*/}
                    <div className=" row flex-stack ">
                      <div className="order-1 col-xl-10  ">
                        <div className="row mb-10 ">
                          <p>
                            <img
                              alt="Logo"
                              src={logo}
                              className="h-30px h-md-50px"
                            />
                          </p>
                        </div>
                        <div className="row mb-5">
                          <div className="col-xl-4 mb-5  ">
                            <a
                              className="text-white fs-3 fw-bold "
                              href="javascript:;"
                              title="email"
                            >
                              邮箱：anyimeta@anyidata.com
                            </a>
                          </div>
                          <div className="col-xl-4 mb-5 ">
                            <a
                              className="text-white fs-3 fw-bold "
                              href="tel:4000131126"
                              title="telephone"
                            >
                              电话：0816-3312888
                            </a>
                          </div>                     
                        </div>
                        <div className="row">
                          <div className="col-xl-12 ">
                            <a className="text-white fs-4 fw-bold ">
                              <p>
                                赤生数字人是行业领先的AIGC数字人孵化平台                               
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 order-2">
                        <div className="text-center">
                          <img
                            style={{ width: 150 }}
                            src={service}
                            alt="wechat"
                          />
                          <p className="mt-2" style={{ color: "#888", fontSize: 11 }}>
                            微信客服
                          </p>
                        </div>
                      </div>
                      {/*begin::Copyright*/}
                      {/*begin::Menu*/}
                      {/*end::Menu*/}
                    </div>
                    {/*end::Wrapper*/}
                    <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                      {/*begin::Copyright*/}
                      <div className="d-flex align-items-center order-2 order-md-1">
                        {/*begin::Logo*/}
                        {/*end::Logo image*/}
                        {/*begin::Logo image*/}
                        <span className="fs-6 fw-bold text-gray-600 me-2">
                          ©2023 赤生数字人  
                         
                        </span>
                        <a href='https://beian.miit.gov.cn'>蜀ICP备2020036123号-2</a>
                        <a
                          className="fs-6 fw-bold text-gray-600 me-2"
                          href="https://beian.miit.gov.cn/"
                          target="_blank"
                        >
                          
                        </a>
                        <a
                          className="fs-6 fw-bold text-gray-600 "
                          target="_blank"
                        >
                          {" "}
                          
                        </a>
                        {/*end::Logo image*/}
                      </div>
                      {/*end::Copyright*/}
                      {/*begin::Menu*/}
                      <ul className="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0 d-none">
                        <li className="menu-item">
                          <a
                            target="_blank"
                            className="menu-link px-2"
                          />
                        </li>
                        <li className="menu-item mx-5">
                          <a
                            target="_blank"
                            className="menu-link px-2"
                          >
                            技术支持
                          </a>
                        </li>
                      </ul>
                      {/*end::Menu*/}
                    </div>
                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Footer Section*/}
              {/*begin::Scrolltop*/}
              <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                <span className="svg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={13}
                      y={6}
                      width={13}
                      height={2}
                      rx={1}
                      transform="rotate(90 13 6)"
                      fill="black"
                    />
                    <path
                      d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </div>
              {/*end::Scrolltop*/}
            </div>
            {/*end::Root*/}
            {/*end::Main*/}
            {/*begin::Modal - Add task*/}
            {video_status==true?<div
             style={{"display":"block","opacity":1}}
              className="modal fade"
              id="video_show"
              tabIndex={-1}
              aria-hidden="true"
              onClick={()=>set_video_status(false)}
            >
              {/*begin::Modal dialog*/}
              <div className="modal-dialog modal-dialog-centered mw-650px">
                {/*begin::Modal content*/}
                <div className="modal-content ">
                  {/*begin::Modal body*/}
                  <div className="modal-body scroll-y " style={{ background: "#000" }}>
                    <video
                      autoPlay="autoplay"
                      controls=""
                      className="w-100"
                      style={{ maxHeight: 800 }}
                      ref={video_ref}
                      src={video_src}
                    ></video>
                  </div>
                  {/*end::Modal body*/}
                </div>
                {/*end::Modal content*/}
              </div>
              {/*end::Modal dialog*/}
            </div>:''}
            {/*end::Modal - Add task*/}    
          </div>
          {login_status==true?<Log_layer />:''}
          {cz_status==true?<My_money />:''}

    </div>
  );
}

export default App;
